<template>
  <b-modal
    id="modal-place-checklist"
    hide-footer
    size="lg"
    header-class="align-items-center"
  >
    <template #modal-header="{ close }">
      <h5 class="mb-0">Agregar Plantilla</h5>
      <b-button
        variant="text"
        @click="closeModal"
        class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
        style="min-height: 24px"
      >
        <i
          class="ri-close-line hp-text-color-dark-0 lh-1"
          style="font-size: 24px"
        ></i>
      </b-button>
    </template>

    <b-form class="mb-8">
      <label class="font-weight-bold" for="">Filtros: </label>
      <b-row>
        <b-col cols="12" md="6" sm="12">
          <b-form-select
            v-model="filters.business_unit"
            :options="wrappedBusinessUnits"
            @change="getChecklistsData"
            type="search"
            class="mb-1"
          ></b-form-select>
        </b-col>
        <b-col cols="12" md="6" sm="12" class="mb-3">
          <b-form-select
            v-model="filters.project_type"
            :options="wrappedTypesProject"
            id="projectType"
            @change="getChecklistsData"
            class="mb-1"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-col>
        <b-col cols="12" md="6" sm="12">
          <b-form-group
            label="Plantilla :"
            label-for="checklistPlace"
            class="mb-16"
          >
            <b-form-select
              v-model="form.checklist"
              :options="wrappedChecklists"
              id="checklistPlace"
              class="mb-1"
              :class="{ 'is-invalid mx-2': $v.form.checklist.$error }"
              value-field="id"
              text-field="name"
            ></b-form-select>
            <b-spinner
              v-if="loading"
              small
              class="mx-auto ml-2"
              variant="warning"
            >
            </b-spinner>
            <div v-if="$v.form.checklist.$error" class="invalid-feedback">
              <span v-if="!$v.form.checklist.required"
                >Tipo de plantilla es requerido.</span
              >
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" sm="12">
          <b-form-group
            label="Sección :"
            label-for="checklistSection"
            class="mb-16"
          >
            <b-form-select
              v-model="form.section"
              :options="wrappedSections"
              id="checklistSection"
              class="mb-1"
              :class="{ 'is-invalid mx-2': $v.form.section.$error }"
              value-field="id"
              text-field="name"
            ></b-form-select>
            <div v-if="$v.form.section.$error" class="invalid-feedback">
              <span v-if="!$v.form.section.required"
                >Sección es requerido.</span
              >
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <div class="d-flex justify-content-end flex-wrap mt-32">
      <b-button variant="outline-info-3" @click="closeModal"> Cerrar </b-button>
      <b-button
        type="submit"
        class="ml-16"
        variant="outline-primary"
        :disabled="loading"
        @click="handleSubmit"
      >
        Agregar plantilla
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");
import { ROLES } from "@/constants/roles";

export default {
  mixins: [validationMixin],
  mounted() {
    this.getBusinessUnits();
    this.getTypesProject();
  },
  data() {
    return {
      textFilterBusinessUnit: "Filtrar por Unidad de Negocio",
      loading: false,
      form: {
        checklist: null,
        section: null,
      },
      filters: {
        business_unit: "Filtrar por Unidad de Negocio",
        project_type: null,
      },
    };
  },
  validations: {
    form: {
      checklist: {
        required,
      },
      section: {
        required,
      },
    },
  },
  methods: {
    ...mapActions({
      getBusinessUnits: "project/getBusinessUnits",
      getTypesProject: "project/getTypesProject",
      createPlaceChecklist: "place/createPlaceChecklist",
      getChecklists: "checklist/getChecklists",
    }),
    setFiltersFromPlace(place) {
      this.filters.business_unit = place.project.business_unit;
      this.filters.project_type = place.project.project_type;
      this.getChecklistsData();
    },
    async getChecklistsData() {
      this.loading = true;
      await this.getChecklists({
        ...this.filters,
        page: 0,
        size: 1000,
        business_unit:
          this.filters.business_unit &&
          this.filters.business_unit !== this.textFilterBusinessUnit
            ? this.filters.business_unit
            : undefined,
      });
      this.loading = false;
    },
    async handleSubmit() {
      this.$v.$touch();
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.loading = true;
        const { ...rest } = this.form;
        let response;
        response = await this.createPlaceChecklist({
          place: this.$route.params.id,
          ...rest,
        });
        this.loading = false;
        if (response.status == 201) {
          this.$bvToast.toast(`Plantilla agregada correctamente`, {
            title: `Excelente`,
            variant: "primary",
            solid: false,
          });
          this.closeModal();
          this.$emit("success");
        }
      }
    },
    openModal() {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.$bvModal.show("modal-place-checklist");
    },
    closeModal() {
      this.$bvModal.hide("modal-place-checklist");
      this.form = {
        checklist: null,
        section: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      checklists: "checklist/checklists",
      businessUnits: "project/businessUnits",
      typesProject: "project/typesProject",
      place: "place/place",
      sections: "project/sections",
      sectionsUser: "project/sectionsUser",
      userAuth: "auth/userAuth",
    }),
    wrappedChecklists() {
      return this.checklists.data
        ? [
            { id: null, name: "Selecciona una plantilla" },
            ...this.checklists.data,
          ]
        : [];
    },
    wrappedSections() {
      if (this.userAuth.rol == ROLES.superadmin) {
        return [{ id: null, name: "Selecciona una Sección" }, ...this.sectionsUser];
      }
      return [{ id: null, name: "Selecciona una Sección" }, ...this.sections];
    },
    wrappedBusinessUnits() {
      return [this.textFilterBusinessUnit, ...this.businessUnits].filter(
        (item) => item
      );
    },
    wrappedTypesProject() {
      return [
        { id: null, name: "Filtrar por Tipo de Proyecto" },
        ...this.typesProject,
      ];
    },
  },
  watch: {
    place: {
      handler: function (place) {
        if (place) {
          this.setFiltersFromPlace(place);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
