<template>
  <b-row>
    <b-col cols="12" class="mb-32 mt-16">
      <b-row align-h="between" class="mt-n32">
        <b-col class="hp-flex-none w-auto mt-32">
          <breadcrumb activeTitle="Plantillas" />
        </b-col>
        <b-col class="hp-flex-none w-auto mt-24">
          <b-row>
            <b-col class="hp-flex-none w-auto pr-0">
              <b-button
                v-if="
                  currentUser &&
                  checkIfHaveRole(currentUser.rol, [
                    ROLES.coordinator,
                    ROLES.superadmin,
                  ])
                "
                @click="$refs.editChecklist.openModal()"
                variant="primary"
                class="btn-ghost"
                >Agregar Plantilla</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12" class="mb-32">
      <h2 class="mb-0">Plantillas</h2>
      <h5 class="mb-0 text-black-80 hp-text-color-dark-30">
        Listado de plantillas del punto de instalación
      </h5>
    </b-col>

    <div class="d-flex align-items-center justify-content-center mx-auto mt-3">
      <b-spinner
        v-if="loading && checklistDOCXProgress.id"
        class="mx-2"
        variant="warning"
      ></b-spinner>
      <span
        class="text-warning mx-1 text-center"
        v-if="
          checklistDOCXProgress.id && !isNaN(checklistDOCXProgress.progress)
        "
        >{{ checklistDOCXProgress.progress }}%</span
      >
    </div>
    <b-col cols="12">
      <datatable :items="computedPlaceChecklists" :fields="fields">
        <template #filters>
          <b-col cols="12" xl="4" md="4" sm="12">
            <b-form-select
              id="SectionType"
              v-model="filters.section"
              class="my-3"
              :options="wrappedSections"
              value-field="id"
              text-field="name"
              @change="
                getPlaceChecklists({
                  place_id: $route.params.id,
                  section: filters.section,
                })
              "
            />
          </b-col>
        </template>
        <template #checklist_type_show="{ item }">
          <span>
            {{
              item.checklist.checklist_type
                ? item.checklist.checklist_type.name
                : "Sin tipo"
            }}
          </span>
        </template>
        <template #status="{ item }">
          <div class="d-flex align-items-center justify-content-center">
            <b-badge
              :id="`${item.checklist.id}-approved-answers`"
              variant="primary"
            >
              {{ item.checklist.status.approved_percent }}%
            </b-badge>
            <b-tooltip :target="`${item.checklist.id}-approved-answers`"
              >Porcentaje de respuestas aprobadas</b-tooltip
            >
            <b-badge
              :id="`${item.checklist.id}-pending-answers`"
              variant="info"
              class="mx-2"
            >
              {{ item.checklist.status.pending_percent }}%
            </b-badge>
            <b-tooltip :target="`${item.checklist.id}-pending-answers`"
              >Porcentaje de respuestas pendientes de aprobar</b-tooltip
            >
          </div>
        </template>
        <template #questions="{ item }">
          <div class="d-flex justify-content-start">
            <b-progress
              :value="item.checklist.status_completed"
              :max="100"
              class="w-75 mr-2"
              variant="warning"
            >
              <b-progress-bar :value="item.checklist.status_completed">
              </b-progress-bar>
            </b-progress>
            <small> {{ item.checklist.status_completed }}% </small>
          </div>
        </template>
        <template #rejected="{ item }">
          <div class="d-flex align-items-center justify-content-center">
            <b-badge
              :id="`${item.checklist.id}-rejected-answers`"
              variant="danger"
            >
              {{ item.checklist.status.rejected_total }}
            </b-badge>
            <b-tooltip :target="`${item.checklist.id}-rejected-answers`"
              >Cantidad de respuestas rechazadas</b-tooltip
            >
          </div>
        </template>
        <template #pending_replacement="{ item }">
          <div class="d-flex align-items-center justify-content-center">
            <b-badge
              :id="`${item.checklist.id}-pending-replacement-answers-percent`"
              variant="primary"
            >
              {{ item.checklist.status.pending_replacement_percent }}%
            </b-badge>
            <b-tooltip
              :target="`${item.checklist.id}-pending-replacement-answers-percent`"
              >Porcentaje de respuestas pendientes de sustitución</b-tooltip
            >
            <b-badge
              :id="`${item.checklist.id}-pending-replacement-answers`"
              variant="info"
              class="mx-2"
            >
              {{ item.checklist.status.pending_replacement }}
            </b-badge>
            <b-tooltip
              :target="`${item.checklist.id}-pending-replacement-answers`"
              >Cantidad de respuestas pendientes de sustitución</b-tooltip
            >
          </div>
        </template>
        <template #replaced="{ item }">
          <div class="d-flex align-items-center justify-content-center">
            <b-badge
              :id="`${item.checklist.id}-replaced-answers-percent`"
              variant="primary"
            >
              {{ item.checklist.status.replaced_percent }}%
            </b-badge>
            <b-tooltip :target="`${item.checklist.id}-replaced-answers-percent`"
              >Porcentaje de respuestas sustituidas</b-tooltip
            >
            <b-badge
              :id="`${item.checklist.id}-replaced-answers`"
              variant="info"
              class="mx-2"
            >
              {{ item.checklist.status.replaced }}
            </b-badge>
            <b-tooltip :target="`${item.checklist.id}-replaced-answers`"
              >Cantidad de respuestas sustituidas</b-tooltip
            >
          </div>
        </template>
        <template #actions="{ item }">
          <div class="d-flex justify-content-center">
            <b-button
              :disabled="!item.checklist.question_count"
              :id="`${item.checklist.id}-show-checklist`"
              size="sm"
              v-b-tooltip.hover
              :title="
                item.checklist.question_count
                  ? 'Responder plantilla'
                  : 'Esta plantilla no tiene preguntas'
              "
              variant="primary"
              @click="goToQuestionsChecklist(item)"
              class="btn-ghost mx-2"
            >
              <i class="iconly-Light-Show"></i>
            </b-button>
            <b-dropdown
              size="sm"
              class="btn-ghost mx-1"
              variant="outline-warning"
            >
              <template #button-content>
                <i class="iconly-Curved-MoreSquare"></i>
              </template>
              <b-dropdown-item
                v-if="
                  currentUser &&
                  checkIfHaveRole(currentUser.rol, [
                    ROLES.coordinator,
                    ROLES.superadmin,
                  ])
                "
                @click="getDocxChecklist(item, 'residencial')"
              >
                Descargar (.docx) Residencial
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  currentUser &&
                  checkIfHaveRole(currentUser.rol, [
                    ROLES.coordinator,
                    ROLES.superadmin,
                  ])
                "
                @click="getDocxChecklist(item, 'oneperpage')"
              >
                Descargar (.docx) una imagen por página
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  currentUser &&
                  checkIfHaveRole(currentUser.rol, [
                    ROLES.coordinator,
                    ROLES.superadmin,
                  ])
                "
                @click="getDocxChecklist(item, 'imagestandar')"
              >
                Descargar (.docx) Imágenes estandar
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  currentUser &&
                  checkIfHaveRole(currentUser.rol, [
                    ROLES.coordinator,
                    ROLES.superadmin,
                  ])
                "
                @click="getDocxChecklist(item, 'comisionamiento')"
              >
                Descargar (.docx) Comisionamiento
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  item.final_document &&
                  currentUser &&
                  checkIfHaveRole(currentUser.rol, [
                    ROLES.coordinator,
                    ROLES.inspector,
                    ROLES.superadmin,
                  ])
                "
                :href="item.final_document"
                target="_blank"
              >
                Descargar (.pdf) plataforma de proyectos
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  currentUser &&
                  checkIfHaveRole(currentUser.rol, [
                    ROLES.coordinator,
                    ROLES.inspesctor,
                    ROLES.superadmin,
                  ])
                "
                @click="
                  $refs.uploadOrGenerateFileProyectPlataform.openModal(item.id)
                "
              >
                Subir PDF a Plataforma Proyectos
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  currentUser &&
                  checkIfHaveRole(currentUser.rol, [
                    ROLES.coordinator,
                    ROLES.superadmin,
                  ])
                "
                @click="deletePlaceChecklist(item)"
              >
                Eliminar plantilla
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </datatable>
    </b-col>

    <update-or-create-place-checklist
      ref="editChecklist"
      @success="
        getPlaceChecklists({
          place_id: $route.params.id,
        })
      "
    ></update-or-create-place-checklist>
    <upload-or-autogenerate-file-to-proyect-plataform
      ref="uploadOrGenerateFileProyectPlataform"
      @pdfUploaded="
        getPlaceChecklists({
          place_id: $route.params.id,
        })
      "
    >
    </upload-or-autogenerate-file-to-proyect-plataform>
  </b-row>
</template>

<script>
import Breadcrumb from "@/layouts/components/content/breadcrumb/Breadcrumb.vue";
import { mapActions, mapGetters } from "vuex";
import UpdateOrCreatePlaceChecklist from "./updateOrCreatePlaceChecklist.vue";
import Datatable from "@/view/components/common/datatable/Datatable.vue";
import { verifyRole } from "@/mixins/role";
import UploadOrAutogenerateFileToProyectPlataform from "./uploadOrAutogenerateFileToProyectPlataform.vue";
import { ROLES } from "@/constants/roles";

export default {
  mixins: [verifyRole],
  components: {
    Breadcrumb,
    UpdateOrCreatePlaceChecklist,
    UploadOrAutogenerateFileToProyectPlataform,
    Datatable,
  },
  async mounted() {
    if (this.userAuth.rol == ROLES.superadmin) {
      await this.getSectionsUser({
        place_id: this.$route.params.id,
      });
      this.filters.section = this.sectionsUser[0].id;
    } else {
      await this.getSections();
      this.filters.section = this.sections[0].id;
    }
    await this.getPlaceChecklists({
      place_id: this.$route.params.id,
    });
    // section: this.filters.section,
  },
  data() {
    return {
      loading: false,
      fields: [
        { key: "actions", label: "Acciones", class: "px-2" },
        {
          key: "checklist.name",
          label: "Nombre",
          sortable: true,
          class: "text-center px-1",
        },
        {
          key: "questions",
          label: "Preguntas",
          sortable: false,
          class: "px-3",
        },
        {
          key: "status",
          label: "Respuestas",
          sortable: false,
          class: "text-center px-2",
        },
        {
          key: "rejected",
          label: "Rechazadas",
          sortable: false,
          class: "text-center px-1",
        },
        {
          key: "pending_replacement",
          label: "Pendientes de sustitución",
          sortable: false,
          class: "text-center pl-2",
        },
        {
          key: "replaced",
          label: "Sustituidas",
          sortable: false,
          class: "text-center px-1",
        },
      ],
      filters: {
        section: null,
      },
    };
  },
  methods: {
    ...mapActions({
      getPlaceChecklists: "place/getPlaceChecklists",
      sendDeletePlaceChecklist: "place/deletePlaceChecklist",
      getDocxFromPlaceChecklist: "checklist/getDocxFromPlaceChecklist",
      getSections: "project/getSections",
      getSectionsUser: "project/getSectionsUser",
    }),
    async getDocxChecklist(item, type) {
      this.$bvToast.toast(`Se esta generando el documento, espere un poco`, {
        title: `Atención`,
        variant: "info",
        solid: false,
      });
      this.loading = true;
      const response = await this.getDocxFromPlaceChecklist({
        place_checklist_id: item.id,
        type,
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const linkToDownloadDocx = document.createElement("a");
      linkToDownloadDocx.setAttribute("href", url);
      linkToDownloadDocx.setAttribute("target", "_blank");
      linkToDownloadDocx.setAttribute(
        "download",
        `${this.place.project.code}-${item.checklist.name}.docx`
      );
      linkToDownloadDocx.click();
      this.loading = false;
    },
    goToQuestionsChecklist(item) {
      this.$router.push({
        name: "place-checklist",
        params: {
          id: this.$route.params.id,
          checklistId: item.checklist.id,
        },
      });
    },
    deletePlaceChecklist(item) {
      this.$bvModal
        .msgBoxConfirm("Esta acción no se puede revertir", {
          title: "¿Estas seguro de eliminar esta plantilla?.",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-info-3",

          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.sendDeletePlaceChecklist(item.id);
            if (resp.status == 204) {
              this.$bvToast.toast(
                `Plantilla de Punto de instalación eliminada correctamente`,
                {
                  title: `Excelente`,
                  variant: "success",
                  solid: false,
                }
              );
            }
            this.getPlaceChecklists({
              place_id: this.$route.params.id,
            });
          }
        })
        .catch((err) => {
          return err;
        });
    },
  },
  computed: {
    ...mapGetters({
      checklistDOCXProgress: "checklist/checklistDOCXProgress",
      placeChecklists: "place/placeChecklists",
      place: "place/place",
      currentUser: "auth/currentUser",
      sections: "project/sections",
      sectionsUser: "project/sectionsUser",
      userAuth: "auth/userAuth",
    }),
    wrappedSections() {
      if (this.userAuth.rol == ROLES.superadmin) {
        return [
          { id: null, name: "Filtrar por Sección" },
          ...this.sectionsUser,
        ];
      }
      return [{ id: null, name: "Filtrar por Sección" }, ...this.sections];
    },
    computedPlaceChecklists() {
      return this.placeChecklists.filter((e) => e);
    },
  },
};
</script>
<style scoped>
.btn.disabled {
  pointer-events: all;
}
</style>
